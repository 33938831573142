<template>
    <div class="container mt-3">

        <h2>
            <i class="bi bi-cash-coin"></i>
            {{ $t('titles.priceList') }}
        </h2>

        <div class="row my-3">
            <div class="col">
                <button
                    v-if="masterUser()"
                    type="button"
                    tag="button"
                    class="btn btn-outline-primary btn-sm mx-3"
                    @click.prevent="createResource()"
                  >
                    <i class="bi bi-pencil-square"></i>
                    {{ $t('form.actions.newPrice') }}
                </button>
            </div>
        </div>



        <div class="d-flex justify-content-center">
            <div v-show="usePrices.state.form.loading" class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <pre>
            {{ usePrices.state.form.resources }}
        </pre>



    </div>
</template>


<script>
// import Vue.js framework functions
import { reactive } from 'vue'
import { onMounted } from 'vue'

// import composables files
import useAuthComp from '@/composables/useAuthComp.js'
import usePricesComp from '@/composables/usePricesComp.js'
//import useItemsComp from '@/composables/useItemsComp.js'



export default {

    name: 'prices',

    setup() {

        const { masterUser } = useAuthComp()


        const usePrices = usePricesComp()
        //const useItems = useItemsComp()

        const state = reactive({
            test: 'test',
        })

        onMounted(() => {

            //state.modal.componentEdit = new Modal(document.getElementById(state.modal.edit))
            //state.modal.componentShow = new Modal(document.getElementById(state.modal.show))
            //state.modal.componentAlert = new Modal(document.getElementById(state.modal.alert))

            // Requested quantity for resources pagination
            //usePrices.state.pagination.meta.requestQtyPerPage = 10

            getAllPrices()
        })


        function getAllPrices() {



            usePrices.getPrices()
            .then(() => {
                usePrices.sortResources('item_id', 'number')
            })
            .catch((error) => {
                usePrices.useErrors.useSetErrors(error.response.data)
            })



        }



        // Erase resource data before insertion in form
        function createResource() {
            console.log('create price')
        }

        return {
            usePrices,
            state,
            masterUser,
            createResource,
        }
    }
}

</script>


<style>
    @import '../../assets/css/logo.css';
</style>
