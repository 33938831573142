/*
|--------------------------------------------------------------------------
| Users management features and utilities composable
|--------------------------------------------------------------------------
|
| This composable JS file contains all common properties and methods
| used in the different views or components for the Items management
| of the application.
*/


// Import Vue.js Framework functions
import { reactive } from 'vue'
import { computed } from 'vue'

// import own utility function library
import useCrudComp from './useCrudComp.js'
import useErrorsComp from '@/composables/useErrorsComp.js'
import useArrayUtilsComp from '@/composables/useArraysUtilsComp.js'

export default function usePricesComp() {

    // define variable with the needed utils functions
    const useCrud = useCrudComp()
    const useErrors = useErrorsComp()
    const useArrayUtils = useArrayUtilsComp()


    // define the reactive variables
    const state = reactive({

        form: {
            body: {
                id: null,
                item_id: null,
                item_name: '',
                item_description: '',
                amount: '',
                validity_from: '',
                validity_to: '',
            },
            status: null,
            resources: null,
            loading: true,

            response: {
                message: null,
                alert: null,
            },
        },

        pagination: {
            meta: {
                currentPage: 0,
                from: 0,
                lastPage: 0,
                perPage: 0,
                to: 0,
                total: 0,
                requestQtyPerPage: 0,
                requestedPage: 0,
            },
            links: {
                prev: null,
                next: null,
            },
        },

        selectedPrices : computed(() => {
            return state.form.resources
        })

    })


    /* ************************************************************************ */
        const tableSort = reactive({
            asc: true,
            col: '',
        })

        // Function to sort Array of Resources
        function sortResources(byKey, type ) {
            useArrayUtils.sortArrayForTables( state.form.resources, byKey, type, tableSort )
        }
    /* ************************************************************************ */


    // Flush all user's attributes
    function flushAttributes() {
        state.form.body.id = null
        state.form.body.item_id = null
        state.form.body.item_name = ''
        state.form.body.item_description = ''
        state.form.body.amount = ''
        state.form.body.validity_from = ''
        state.form.body.validity_to = ''
    }


    function getPrices() {
        state.form.loading = true

        return useCrud.getAllResources({
            url: 'prices'
        })
        .then((res) => {
            state.form.resources = res.data.data
            state.form.loading = false
            state.form.status = 'success'
        })
    }

    function getPricesFromItemId(item_id) {
        state.form.loading = true

        return useCrud.getAllResources({
            url: 'items/' + item_id + '/prices'
        })
        .then((res) => {
            state.form.resources = res.data.data
            state.form.loading = false
            state.form.status = 'success'

            return res.data.data
        })
    }


    function storeResource() {

        useErrors.useResetErrors()
        state.form.status = 'saving'

        return useCrud.storeResource({
            url: 'items/' + state.form.body.item_id + '/prices',
            resource: state.form.body
        })
        .then((res) => {
            state.form.resources.push(res.data.resource)

            state.form.status = 'success'
        })
    }


    function updateResource() {

        console.log('update resource')
    }



    // Delete resource in backend
    function deleteResource(resource) {

        return useCrud.deleteResource({
            url: 'prices/' + resource.id,
            resource: resource.id
        })
        .then(() => {
            var index = state.form.resources.findIndex(x => x.id === resource.id)
            state.form.resources.splice(index, 1)

            state.form.status = 'success'
        })

    }





    return {
        useErrors,
        sortResources,
        flushAttributes,
        state,
        getPrices,
        getPricesFromItemId,
        storeResource,
        updateResource,
        deleteResource,
    }
}
